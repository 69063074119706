import React, { useRef } from "react";
import ReactDOM from "react-dom";
// import { Editor, EditorState } from "draft-js";
import { Editor } from "@tinymce/tinymce-react";
export default function Admin() {
  const editorRef = useRef();
  // const [editorState, setEditorState] = React.useState(
  //   EditorState.createEmpty()
  // );

  // const editor = React.useRef(null);

  // function focusEditor() {
  //   editor.current.focus();
  // }

  // React.useEffect(() => {
  //   focusEditor();
  // }, []);

  return (
    <div>
      <h1>TEST</h1>
      <Editor onInit={(evt, editor) => (editorRef.current = editor)} />
    </div>
  );
}

// ReactDOM.render(<Admin />, document.getElementById("container"));
