import React from "react";
import "./Bars.scss";
import TopBar from "./TopBar/TopBar";
import ToggleButton from "./SideDrawer/ToggleButton";
import SideDrawer from "./SideDrawer/SideDrawer";
import Backdrop from "./Backdrop/Backdrop";
import NavBar from "./NavBar/NavBar.js";
import { useState } from "react";
// import { NavLink } from "react-router-dom";

export default function Bars() {
  const [barName, setBarName] = useState("bars");
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };
  const removeBackdrop = () => {
    setSideDrawerOpen(false);
  };
  return (
    <div className={barName}>
      <TopBar />
      <div className="bars__links">
        <Backdrop clicked={removeBackdrop} show={sideDrawerOpen} />
        <NavBar />
        <ToggleButton clicked={drawerToggleClickHandler} />
        <SideDrawer show={sideDrawerOpen} clicked={drawerToggleClickHandler} />
      </div>
    </div>
  );
}
