import React, { useState, lazy, Suspense } from "react";
import "./Home.scss";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
import AdvModule from "../../components/AdvModule/AdvModule";
import { newsShedule, options } from "../../shared/shared";
import HeadHelmet from "../../UI/HeadHelmet/HeadHelmet";
import { useLocation } from "react-router-dom";

export default function Home(props) {
  const actNewsFunc = () => {
    let actualNewsFilter = props.data.filter((item) => {
      return newsShedule[0].home.includes(item.news_id);
    });
    return actualNewsFilter;
  };
  const [actualNews, setActualNews] = useState(actNewsFunc());
  let location = useLocation();
  const getNews = (num, classes) => {
    return num.map((n, index) => {
      return <ArticalBox key={index} classes={classes} news={props.data[n]} />;
    });
  };
  return (
    <div className="home__1">
      <HeadHelmet
        title={"Bulevar umetnosti - Magazin"}
        post={options[0].social}
        location={location}
      />
      {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
      <section className="home__box__4">
        <div className="home__box__4-layout">
          {getNews([0, 1], "boxWrapper mainBox bigBox")}
          {getNews([2, 3, 4, 5], "boxWrapper box4")}
        </div>
      </section>
      <section className="home__box__3">
        <div className="home_3_title">
          <h2>Aktuelno</h2>
        </div>
        <div className="box3">
          <div className="box3__left">
            {getNews([0, 1, 2], "boxWrapper box3")}
          </div>
        </div>
      </section>
      <section className="home__box__2">
        <div className="home__leftBox">
          {getNews([4, 5, 6], "boxWrapper box2")}
        </div>
        <div className="home__rightBox">
          <AdvModule classes={"adClass"} size={"xl"} />
        </div>
      </section>
      <section className="home__box__3">
        <div className="home_3_title">
          <h2>Pročitajte još...</h2>
        </div>
        <div className="box3">
          <div className="box3__left">
            {getNews([7, 8, 9], "boxWrapper box3")}
          </div>
        </div>
      </section>
      {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
      {/* <section className="home__box__1">
        <ArticalBox n={0} classes="mainNews" />
        <div className="home__rightBox">
          {getNews([1, 2, 3], "boxWrapper box1")}
        </div>
      </section>
      <AdvModule classes="adClass__XXL" size={"xxl"} /> */}
      {/* <Backdrop 
      clicked={this.removeCommentBox} 
      show={this.state.showPopup} /> */}
    </div>
  );
}
