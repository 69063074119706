import React from "react";
import "./Footer.scss";
import { footer } from "../../shared/shared.js";
import { NavLink } from "react-router-dom";
export default function Footer() {
  return (
    <div className="footer">
      <div className="home__section5-top">
        {footer.map((item, index) => {
          return (
            <NavLink to={item[1]} key={index}>
              <span key={index}>{item[0]}</span>
            </NavLink>
          );
        })}
      </div>
      <div className="home__section5-middle">
        <div>
          <span>Pratite nas</span>
          <i className="fab fa-facebook-square"></i>
          <i className="fab fa-twitter-square"></i>
          <i className="fab fa-youtube-square"></i>
        </div>
        <div>
          <span>Mobile app</span>
          <i className="fas fa-mobile-alt"></i>
          <i className="fab fa-apple"></i>
        </div>
      </div>
      <div className="home__section5-bottom">
        <span>© 2021 - 2022 Bulevar umetnosti® Serbia. </span>
        <span>
          Developed by{" "}
          <a target="_blank" href="https://tausolutions.rs/">
            TAUsolutions
          </a>
        </span>
      </div>
    </div>
  );
}
