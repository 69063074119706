import React from "react";
import Helmet from "react-helmet";

export default function HeadHelmet(props) {
  console.log("props", props);
  console.log("pathname", props.location.pathname);
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {/* <title>{setUpperText(props.post.title)}</title> */}
      <title>{props.post.title}</title>

      <link
        rel="canonical"
        href={"https://bulevarumetnosti.rs" + props.location.pathname}
      />
      <meta name="description" content={setUpperText(props.post.lead)} />
      {/* Opengraph - Facebook */}
      <meta property="og:locale" content="sr-RS" />
      <meta property="og:type" content="article" />
      <meta property="og:description" content={props.post.lead} />
      <meta property="og:title" content={props.post.title} />
      <meta property="og:site_name" content={"Agroweb.rs"} />
      <meta property="og:image" content={props.post.pics[0]} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="800" />
      <meta
        property="og:url"
        content={"https://bulevarumetnosti.rs" + props.location.pathname}
      />
      {/* End Opengraph */}
    </Helmet>
  );
}
const setUpperText = (title) => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};
{
  /* <Helmet>
<meta charSet="utf-8" />
<title>{setTitle(newsPost)}</title>
<link
  rel="canonical"
  href={"https://bulevarumetnosti.rs" + location.pathname}
/>
<meta name="description" content={newsPost.lead} />
Opengraph - Facebook
<meta property="og:locale" content="sr-RS" />
<meta property="og:type" content="article" />
<meta property="og:description" content={newsPost.lead} />
<meta property="og:title" content={setTitle(newsPost)} />
<meta property="og:site_name" content={"Bulevarumetnosti.rs"} />
<meta property="og:image" content={newsPost.pics[0]} />
<meta property="og:image:width" content="1200" />
<meta property="og:image:height" content="800" />
<meta
  property="og:url"
  content={"https://bulevarumetnosti.rs" + location.pathname}
/>
End Opengraph
</Helmet> */
}
