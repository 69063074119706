import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./SinglePost.scss";
import AdvModule from "../../components/AdvModule/AdvModule";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
import BodyText from "../../components/BodyText/BodyText";
import SocialButtons from "../../UI/SocialButtons/SocialButtons";
import HeadHelmet from "../../UI/HeadHelmet/HeadHelmet";
import TimeFormat from "../../components/TimeFormat/TimeFormat";
import Helmet from "react-helmet";
import { urlTitle2 } from "../../shared/utillity";

export default function SinglePost(props) {
  const { id } = useParams();
  let location = useLocation();
  const newData = () => {
    let dd = props.data.filter((item) => {
      return urlTitle2(item.title) === id;
    });
    return dd[0];
  };
  const [newsPost, setNewsPost] = useState(newData);
  useEffect(() => {
    setNewsPost(newData);
  }, [location.pathname]);

  const newsAside = (title) => {
    return newsPost ? (
      <div className="newsAside-box">
        <h4>{title}</h4>
        {props.data.slice(0, 3).map((news, index) => {
          // remove +3
          return (
            <ArticalBox
              key={index}
              news={news}
              classes={"boxWrapper sideBox"}
            />
          );
        })}
      </div>
    ) : (
      <div>Loading newsAside...</div>
    );
  };
  return (
    <div className="singlePost">
      {/* <HeadHelmet title={id} post={newsPost} location={location} /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{newsPost.title}</title>
        <link
          rel="canonical"
          href={"https://bulevarumetnosti.rs" + location.pathname}
        />
        <meta name="description" content={newsPost.lead} />
        {/* Opengraph - Facebook */}
        <meta property="og:locale" content="sr-RS" />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={newsPost.lead} />
        <meta property="og:title" content={newsPost.title} />
        <meta property="og:site_name" content={"Bulevarumetnosti.rs"} />
        <meta property="og:image" content={newsPost.pics[0]} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:url"
          content={"https://bulevarumetnosti.rs" + location.pathname}
        />
        {/* End Opengraph */}
      </Helmet>
      <div className="content">
        <main className="mainContent">
          <article className="mainContent-wrapper">
            <h1 className="mainContent-title">
              {newsPost.title_2 || newsPost.title}
            </h1>
            <strong className="mainContent-lead">{newsPost.lead}</strong>
            <SocialButtons location={location} />
            <div className="time">
              Datum:{" "}
              <TimeFormat
                timePost={
                  newsPost.modified ? newsPost.modified : newsPost.time2
                }
              />
              , Izvor: {newsPost.source}
            </div>
            <div className="mainContent-img">
              <img src={`${newsPost.pics[0]}`} alt="" />
              <div className="mainContent-img_source">
                <span> Foto: </span>
                <span
                  dangerouslySetInnerHTML={{ __html: newsPost.pics[1] }}
                ></span>
                {/* {newsPost.pics[1]} */}
              </div>
            </div>
            <div>
              <BodyText bodyText={newsPost.body} />
            </div>
            <div className="tags">
              <span className="tags-title">Tagovi</span>
              <div className="tags__arr">
                {newsPost.tags.map((x, index) => {
                  return <span key={index}>{x}</span>;
                })}
              </div>
            </div>
          </article>
        </main>
        <aside className="newsAside">
          {["Najnovije vesti"].map((x, index) => {
            return (
              <React.Fragment key={index}>
                <AdvModule classes={"adClass"} size={"xl"} />
                {newsAside(x)}
              </React.Fragment>
            );
          })}
        </aside>
      </div>
    </div>
  );
}
