import React from "react";
import CookieConsent from "react-cookie-consent";
const handleOnAccept = () => {
  window.localStorage.setItem("cookieConsentAccepted", true);
};
const cookieConsentAccepted = window.localStorage.getItem(
  "cookieConsentAccepted"
);
export default function Cookies() {
  if (!cookieConsentAccepted) {
    // if (1 === 1) {
    return (
      <CookieConsent
        onAccept={handleOnAccept}
        style={{ background: "#153a3f" }}
        buttonText="Prihvatam"
        buttonStyle={{ background: "#dc391c", color: "white" }}
        debug={true}
      >
        Bulevarumetnosti.rs koristi kolačiće kako bi ti pružao najbolje
        korisničko iskustvo. Nastavkom korišćenja sajta smatraćemo da imamo
        saglasnost sa korišćenjem kolačića. Više o kolačićima možeš pročitati{" "}
        <a style={{ color: "#fe662e" }} href="/politika-privatnosti">
          ovde
        </a>
        .
      </CookieConsent>
    );
  } else {
    return null;
  }
}
