import React from "react";
import Buttons from "../../UI/Button/Buttons";
import "./AxiosTest.scss";
import fs from "fs";
// const fs = require("fs");
function saveContent() {
  console.log("TEST");
  fs.readFile("testData.JSON", (error, data) => {
    if (error) {
      console.error("TEST error", error);
    }
    const dataObj = JSON.parse(data);
    dataObj.name = "Jane";
    dataObj.age = 35;

    const updateData = JSON.stringify(dataObj);

    fs.writeFile("data.json", updateData, (error) => {
      if (error) {
        console.error("Error", error);
      }
      console.log("Uspesno JSON azuriran");
    });
  });
}

// import * as fs from "fs";
export default function AxiosTest() {
  //   const axios = require("axios");

  const saveContent2 = () => {
    console.log("IDEMOOO");
  };
  return (
    <div className="axiosTest">
      AxiosTest
      <br />
      <Buttons clicked={saveContent}>TEST</Buttons>
      <Buttons clicked={saveContent2}>TEST 2</Buttons>
      {/* <button>TEST</button> */}
    </div>
  );
}
