export const adList2 = [
  {
    adUrl: "/img/ads_img/agroweb.png",
    alt: "",
    size: "xl",
    route: "http://agroweb.rs/",
  },
  {
    adUrl: "/img/ads_img/agroweb2.png",
    alt: "",
    size: "xl",
    route: "http://agroweb.rs/",
  },
  {
    adUrl: "/img/ads_img/selfPromo300x600.png",
    alt: "",
    size: "xl",
    route: "http://bulevarumetnosti.rs/",
  },
  {
    adUrl: "/img/ads_img/test_728x90.jpg",
    alt: "",
    size: "xxl",
    route: "https://bulevarumetnosti.rs",
  },
  {
    adUrl: "/img/ads_img/test_728x90.jpg",
    alt: "",
    size: "xxl",
    route: "https://bulevarumetnosti.rs",
  },
  {
    adUrl: "/img/ads_img/test_728x90.png",
    alt: "",
    size: "xxl",
    route: "https://bulevarumetnosti.rs",
  },
  {
    adUrl: "/img/ads_img/test_728x90.png",
    alt: "",
    size: "xxl",
    route: "https://bulevarumetnosti.rs",
  },
  {
    adUrl: "/img/ads_img/test_728x90.png",
    alt: "",
    size: "xxl",
    route: "https://bulevarumetnosti.rs",
  },
  {
    adUrl: "/img/ads_img/test_728x90.png",
    alt: "",
    size: "e",
    route: "https://bulevarumetnosti.rs",
  },
  {
    adUrl: "/img/ads_img/test_728x90.png",
    alt: "",
    size: "s",
    route: "https://bulevarumetnosti.rs",
  },
];
