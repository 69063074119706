export const details = [
  { title: "Ime i prezime", name: "name" },
  { title: "Kontakt telefon", name: "tel" },
  { title: "Vaša poruka", name: "text" },
];
export const newsShedule = [
  { home: [34, 33, 20] }, //actual news ids
];
export const options = [
  {
    route: "/",
    title: "Početna",
    icon: "/img/icons/home.png",
    end: true,
    social: {
      title: "Bulevar umetnosti - Magazin",
      lead: "Bulevar umetnosti - Magazin - Najnovije vesti iz kulture",
      pics: "/img/test3.jpg",
    },
  },
  // {
  //   route: "/vesti",
  //   title: "Vesti",
  //   item_list: [
  //     "Stocarstvo",
  //     "Ratarstvo",
  //     "Pcelarstvo",
  //     "Mehanizacija",
  //     "Zađtita bilja",
  //     "Povrtarstvo",
  //     "Prehranbena industrija",
  //     "Ukrasno bilje",
  //     "Vinogradarstvo",
  //   ],
  // },

  // {
  //   route: "/vesti/stocarstvo",
  //   title: "Vesti",
  //   icon: "/img/icons/stocarstvo.png",
  // },
  {
    route: "/vesti/umetnost",
    title: "Umetnost",
    icon: "/img/icons/umetnost.png",
  },
  {
    route: "/vesti/pozorista",
    title: "Pozorišta",
    icon: "/img/icons/pozorista.png",
  },
  {
    route: "/vesti/bioskopi",
    title: "Bioskopi",
    icon: "/img/icons/bioskopi.png",
  },

  // { route: "/zivot-na-selu", title: "Život na selu" },
  // { route: "/subvencije", title: "Subvencije" },
  // { route: "/IPARD", title: "IPARD" },
  { route: "/vesti/izlasci", title: "Izlasci", icon: "/img/icons/izlasci.png" },
  // { route: "/zdrav-zivot", title: "Zdrav život" },
  // { route: "/e-pijaca", title: "E-pijaca" },
  { route: "/vesti/ostalo", title: "Ostalo", icon: "/img/icons/other.png" },
];

export const footer = [
  ["O nama", "/o-nama"],
  ["Marketing", "/marketing"],
  ["Pravila korišćenja", "/pravila-koriscenja"],
  ["Politika privatnosti", "/politika-privatnosti"],
];
