import React from "react";
import "./BulevarUmetnosti.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Bars from "./components/Bars/Bars";
import Home from "./containers/Home/Home";
import Footer from "./components/Footer/Footer";
import SinglePost from "./containers/SinglePost/SinglePost.js";
// import { useSelector, useDispatch } from "react-redux";
import data from "./all__news.json";
import News from "./containers/News/News";
import NoMatch from "./containers/NoMatch/NoMatch";
// import { increment, decrement } from "./action/Index.js";
import Cookies from "./UI/Cookies/Cookies";
import ScrollToTop from "./UI/ScrollToTop/ScrollToTop";
import Admin from "./containers/Admin/Admin";
import AxiosTest from "./containers/AxiosTest/AxiosTest";
import HeadHelmet from "./UI/HeadHelmet/HeadHelmet";

export default function BulevarUmetnosti() {
  // let xhttp = new XMLHttpRequest();
  // xhttp.onreadystatechange = function () {
  //   if (this.readyState == 4 && this.status == 200) {
  //     console.log(xhttp.responseText);
  //   }
  // };
  // xhttp.open("GET", "https://jsonplaceholder.typicode.com/albums", true);
  // xhttp.send();
  // let request = fetch("https://jsonplaceholder.typicode.com/albums")
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log(data);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });

  //change variables names and check if its posible to remove index

  let currentDay1 = new Date().setHours(0, 0, 0, 0);
  const publishedNews = data.filter((item, index) => {
    let currentDay2 = new Date(data[index].time2).setHours(0, 0, 0, 0);
    return item.published && currentDay1.valueOf() >= currentDay2.valueOf();
  });
  const publishedNewsPromo = publishedNews.filter((item, index) => {
    return item.promoExcluded !== true;
  });
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* <Router> */}
      <ScrollToTop />
      <div className="app">
        <Bars />
        <div className="app-wrapper">
          <div className="app-body">
            <Routes>
              <Route
                path="/"
                element={<Home data={publishedNewsPromo} />}
                n={false}
              />
              <Route path="/admin" element={<Admin />} />
              <Route path="/testAxios" exact element={<AxiosTest />} />
              <Route
                path="/vesti/:id/:id"
                element={<SinglePost data={publishedNews} />}
              />
              <Route
                path="/vesti/:id"
                element={<News data={publishedNews} />}
              />
              {/* <Route path="/home" element={<Navigate replace to="/" />} /> */}
              <Route
                // path="/art-info/konkursi/702-foto-konkurs-viki-voli-zemlju-2016"
                path="/:id/:id/:id"
                element={<NoMatch />}
              />
              <Route
                // path="/art-info/konkursi/702-foto-konkurs-viki-voli-zemlju-2016"
                path="/:id/:id"
                element={<NoMatch />}
              />

              <Route path="*" component={NoMatch} />
            </Routes>
          </div>
        </div>
        <Cookies />
        <Footer />
      </div>
    </Router>
  );
}
