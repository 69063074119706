import React, { useState } from "react";
import {
  ViberIcon,
  FacebookShareButton,
  ViberShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export default function SocialButtons(props) {
  const [classLink, setCopyLink] = useState(" close");
  const [linkText, setLinkText] = useState("Kopiraj link");
  // console.log("Props", props);
  return (
    <div className="social">
      <span>Podeli na:</span>
      <FacebookShareButton
        url={`https://bulevarumetnosti.rs${props.location.pathname}`} //eg. https://www.example.com
        style={{ zIndex: "+1" }}
      >
        <FacebookIcon size={"32px"} />
      </FacebookShareButton>
      <ViberShareButton
        url={`https://bulevarumetnosti.rs${props.location.pathname}`}
        style={{ zIndex: "+1" }}
      >
        <ViberIcon size={"32px"} />
      </ViberShareButton>
      <WhatsappShareButton
        url={`https://bulevarumetnosti.rs${props.location.pathname}`}
        style={{ zIndex: "+1" }}
      >
        <WhatsappIcon size={"32px"} />
      </WhatsappShareButton>
      <div className="chain">
        <span className={`copiedLink${classLink}`}>{linkText}</span>
        <img
          src="/img/icons/chain.png"
          alt="chain"
          onMouseOver={() => {
            setCopyLink(" cov");
          }}
          onClick={() => {
            setLinkText("Link je kopiran");
            setCopyLink("");
            navigator.clipboard.writeText(
              `https://bulevarumetnosti.rs${props.location.pathname}`
            );
          }}
          onMouseLeave={() => {
            setCopyLink(" close");
            setLinkText("Kopiraj link");
          }}
        />
      </div>
    </div>
  );
}
