import React from "react";
import "./ArticleBox.scss";
import { Link } from "react-router-dom";
// import news from "../../all__news.json";
import { useState } from "react";
import TimeFormat from "../TimeFormat/TimeFormat";

const urlTitle = (name) => {
  const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
  const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
  let regex = /--/gi;
  let d = name
    .toLowerCase()
    .split("")
    .map((x) => {
      return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
    });
  return d.join("").split(" ").join("-").replace(regex, "-");
};
export default function ArticalBox(props) {
  const [fallBackSrc, setFallBackSrc] = useState(false);

  // const urlCategory = (name) => {
  //   const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
  //   const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
  //   let regex = /--/gi;
  //   let d = name
  //     .toLowerCase()
  //     .split("")
  //     .map((x) => {
  //       return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
  //     });
  //   return d.join("").split(" ").join("-").replace(regex, "-");
  // };

  const setTitle = (postTitle) => {
    return postTitle.title_2 ? postTitle.title_2 : postTitle.title;
  };

  let imgSrc = !fallBackSrc ? props.news.pics[0] : fallBackSrc;
  // console.log("Props", props.news.pics[0]);
  return (
    <Link
      to={
        "/vesti/" +
        urlTitle(props.news.category) +
        "/" +
        urlTitle(props.news.title)
      }
      className={props.classes}
    >
      <div className="artBox-wrapper">
        <img src={imgSrc} alt="" loading="lazy" />
        <div className="artBox-desc">
          <h2>{setTitle(props.news)}</h2>
          <div className="artBox-desc-other">
            <Link to={"/vesti/" + urlTitle(props.news.category)}>
              {props.news.category}
            </Link>
            <span>
              | <TimeFormat timePost={props.news.time2} classes={"noDay"} />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}
// }
