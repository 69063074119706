const initialState = {
  posts: [
    { id: 1, title: "1111111111", body: "aaaaaaaa" },
    { id: 2, title: "2222222222", body: "bbbbbbbbbbb" },
    { id: 3, title: "3333333333", body: "ccccccccc" },
  ],
};

const counterReducer = (state = initialState, action) => {
  console.log("Action", action);
  if (action.type === "DELETE_POST") {
    let newPosts = state.posts.filter((post) => {
      return action.id !== post.id;
    });
    return {
      ...state,
      posts: newPosts,
    };
  } else {
    return state;
  }
};

export default counterReducer;
