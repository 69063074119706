import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Buttons from "../../UI/Button/Buttons";

export default function NoMatch() {
  const { id } = useParams();
  const loc = useLocation();
  console.log("ID", id);
  console.log("ID", loc);
  return (
    <div>
      <span>UUPS!</span>
      <h2>Greška 404: Stranica nije pronađena</h2>
      <p>
        Nešto se dogodilo! Trenutno ne možemo da pronađemo stranicu koju trazite
        ili stranica ne postoji. <br /> Molimo vas da osvežite stranicu ili
        pokušate da odete na neku drugu.
      </p>
      <Buttons children="Nazad na početnu" />
    </div>
  );
}
