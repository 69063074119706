import React, { useState, useEffect } from "react";
import "./News.scss";
import AdvModule from "../../components/AdvModule/AdvModule";
import { options } from "../../shared/shared.js";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const urlTitle2 = (title) => {
  const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
  const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
  let regex = /--/gi;
  let url_title = title
    .toLowerCase()
    .split("")
    .map((x) => {
      return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
    })
    .join("")
    .split(" ")
    .join("-")
    .replace(regex, "-");
  return url_title;
};

export default function News(props) {
  const { id } = useParams();
  const data = () => {
    let newData = props.data.filter((item) => {
      return urlTitle2(item.category) === id;
    });
    return newData;
  };
  const [filtered, setFiltered] = useState(data);
  useEffect(() => {
    setFiltered(data);
  }, [id]);

  const pageTitle = () => {
    let setTitle = options.filter((item) => {
      return item.route.substring(7) === id;
    });
    return setTitle[0].title;
  };
  const setUpperText = () => {
    return (
      filtered[0].category.charAt(0).toUpperCase() +
      filtered[0].category.slice(1)
    );
  };
  const filteredNews_2 = (n, newClass2, newClass22) => {
    return filtered ? (
      <div className={newClass2}>
        {filtered.slice(n[0], n[1]).map((item, index) => {
          return <ArticalBox key={index} news={item} classes={newClass22} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };
  return (
    <div className="news">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{setUpperText()}</title>
        <link
          rel="canonical"
          href={
            "https://bulevarumetnosti.rs/vesti/" +
            urlTitle2(filtered[0].category)
          }
        />
        <meta
          name="description"
          content={setUpperText() + " - najnovije vesti"}
        />
      </Helmet>
      {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
      <h1>{pageTitle()}</h1>
      <section className="home__box__1-1">
        {filteredNews_2([0, 1], "home__box", "mainNews", "")}
      </section>
      <section className="home__box__11">
        {filteredNews_2(
          [1, 4],
          "home__rightBox",
          "boxWrapper box2 news3",
          "home__adBox"
        )}
        <div className="home__adBox">
          <AdvModule classes={"adClass"} size={"xl"} />
        </div>
      </section>
      <section className="home__box__2">
        {filteredNews_2(
          [4, 7],
          "home__box__2-layout",
          "boxWrapper mainBox",
          "no-add"
        )}
        {/* <div className={newClass3}>
          <AdvModule classes={"adClass"} size={"xl"} />
        </div> */}
      </section>
      <div className="home__rightBox">
        {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
      </div>
    </div>
  );
}
