import React from "react";
import { Tweet } from "react-twitter-widgets";
// import AdvModule from "../AdvModule/AdvModule";
import "../../containers/SinglePost/SinglePost.scss";
import "./BodyText.scss";

export default function BodyText(props) {
  const setImg = (e) => {
    if (e.length > 0) {
      return (
        <div className="mainContent-img">
          <img src={`${e[0]}`} alt="" />
          <div className="mainContent-img_source">Foto: {e[1]}</div>
        </div>
      );
    }
  };
  const setSubTitle = (subTitle) => {
    if (subTitle.length > 0) {
      return <h2 className="mainContent-subtitle">{subTitle}</h2>;
    }
  };
  const setTweet = (e) => {
    if (e > 0) {
      return <Tweet tweetId={e} />;
    }
  };
  return (
    <>
      {props.bodyText.map((y, index) => {
        if (index === 0) {
          return (
            <>
              {setSubTitle(y.subtitle)}
              <p dangerouslySetInnerHTML={{ __html: y["text"] }}></p>
              {setTweet(y.tweetId)}
              {/* <AdvModule classes="adClass__S" size={"s"} /> */}
              {/* <a href=""></a> */}
            </>
          );
        } else {
          return (
            <>
              {setSubTitle(y.subtitle)}
              {y.otherPics && setImg(y.otherPics)}
              <p dangerouslySetInnerHTML={{ __html: y["text"] }}></p>
              {setTweet(y.tweetId)}
              {/* <a href=""></a> */}
            </>
          );
        }
      })}
    </>
  );
}
