import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.scss";
import { options } from "../../../shared/shared";
import { useState } from "react";

export default function NavBar() {
  const [dropDown, setDropDown] = useState(false);
  const navBarOptions = () => {
    return (
      <div className="links">
        {options.map((option, index) => {
          return (
            <NavLink
              key={index}
              to={option.route}
              className="nav-link"
              onMouseOver={() => {
                setDropDown(true);
              }}
              onMouseLeave={() => {
                setDropDown(true);
              }}
              end={option.end}
            >
              {option.title}
              {items_list(option.item_list)}
            </NavLink>
          );
        })}
      </div>
    );
  };
  const getDropDownMenu = () => {
    return dropDown ? "drop_down_menu" : "drop_down_menu close";
  };
  const items_list = (items) => {
    if (items) {
      return (
        <ul className={getDropDownMenu()}>
          {items.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      );
    }
  };
  return <div className="navBar">{navBarOptions()}</div>;
}
