import React from "react";
import "./TopBar.scss";
import { NavLink } from "react-router-dom";
import { useState } from "react";

export default function TopBar() {
  const [showBar, setShowBar] = useState("topBar");
  // export default class TopBar extends React.Component {
  // state = {
  //   showBar: "topBar",
  // };
  // componentDidMount = () => {
  //   window.addEventListener("scroll", this.handleScroll);
  // };

  // handleScroll = () => {
  //   if (window.pageYOffset < 150) {
  //     this.setState({
  //       showBar: "topBar",
  //     });
  //   } else {
  //     this.setState({
  //       showBar: "topBar close",
  //     });
  //   }
  // };
  // render() {
  return (
    <div className={showBar}>
      <div className="full-title">
        <NavLink to="/">Bulevar umetnosti</NavLink>
      </div>
      {/* <h1>Bulevar umetnosti</h1> */}
      {/* <NavLink to="/kontakt" exact className="topBar__link">
          office@bulevarumetnosti.rs
        </NavLink>
        <div className="topBar__link">
          <i className="fab fa-facebook"></i>
          <i className="fab fa-instagram"></i>
          <i className="fab fa-twitter-square"></i>
        </div> */}
    </div>
  );
}
