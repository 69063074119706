import React from "react";
import "./AdvModule.scss";
import { adList2 } from "../../shared/adList.js";
import { useState } from "react";

const getUrl = (classes, size, count) => {
  // const[ss,setSS]=useState()
  if (size === "xxl") {
    let adListXxl = adList2.filter((x) => {
      return x.size === "xxl";
    });
    let a = Math.random() * (adListXxl.length - 0) + 0;
    let xx = parseInt(a);
    return (
      <a className={classes} target="_blank" href={adListXxl[xx].route}>
        <img src={adListXxl[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  } else if (size === "xl") {
    // setCount()
    let adListXl = adList2.filter((x) => {
      return x.size === "xl";
    });
    let a = Math.random() * (adListXl.length - 0) + 0;
    let xx = parseInt(a);
    return (
      <a className={classes} target="_blank" href={adListXl[xx].route}>
        <img src={adListXl[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  } else if (size === "s") {
    let adListXl = adList2.filter((x) => {
      return x.size === "s";
    });
    let a = Math.random() * (adListXl.length - 0) + 0;
    let xx = parseInt(a);
    return (
      <a className={classes} target="_blank" href={adListXl[xx].route}>
        <img src={adListXl[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  } else if (size === "e") {
    let adListXl = adList2.filter((x) => {
      return x.size === "e";
    });
    let a = Math.random() * (adListXl.length * 3) + 0;
    let xx =
      Math.round(a) >= adListXl.length ? (
        ""
      ) : (
        <a
          className={classes}
          target="_blank"
          href={adListXl[Math.round(a)].route}
        >
          <img src={adListXl[Math.round(a)].adUrl} alt="" loading="lazy" />
        </a>
      );
    return xx;
  } else {
    let adListL = adList2.filter((x) => {
      return x.size === "l";
    });
    let a = Math.random() * (adListL - 1) + 0;
    let xx = parseInt(a);
    return (
      <a className={classes} target="_blank" href={adListL[xx].route}>
        <img src={adListL[xx].adUrl} alt="" loading="lazy" />
      </a>
    );
  }
};
export default function AdvModule(props) {
  console.log("Mount AdModule");
  const [count, setCount] = useState(1);
  return (
    <div className="ad_wrapper">{getUrl(props.classes, props.size, count)}</div>
  );
}
